<template>
  <div>
    <b-modal
      id="modal-input-user-tenaga-medis"
      size="lg"
      centered
      :title="'Tambah Data User'"
      header-bg-variant="primary"
      header-text-variant="light"
      @show="openModal()"
      @hidden="resetModal"
    >
      <b-form>
        <b-form-group label-cols-md="3">
          <template v-slot:label> Username <span class="text-danger">*</span> </template>
          <b-form-input
            :state="checkIfValid('username')"
            type="text"
            v-model="$v.dataForm.username.$model"
          ></b-form-input>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label> Password <span class="text-danger">*</span> </template>
          <b-input-group>
            <b-form-input
              :state="checkIfValid('password')"
              :type="is_text ? 'text' : 'password'"
              v-model="$v.dataForm.password.$model"
            ></b-form-input>

            <template #append>
              <b-input-group-text @click="is_text = !is_text">
                <b-icon :icon="is_text ? 'eye' : 'eyeSlash'"></b-icon>
              </b-input-group-text>
            </template>
          </b-input-group>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label> Role <span class="text-danger">*</span> </template>
          <multiselect
            id="poliklinik"
            v-model="dataForm.ms_role_id"
            :options="listRole"
            :multiple="false"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            label="nama_role"
            placeholder="-- Role --"
            size="sm"
            :state="checkIfValid('ms_role_id')"
          ></multiselect>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label> Nama </template>
          <b-form-input
            type="text"
            v-model="dataForm.nama"
            disabled
          ></b-form-input>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label> Alamat </template>
          <b-form-input
            type="text"
            v-model="dataForm.alamat"
            disabled
          ></b-form-input>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label> Puskesmas </template>
          <b-form-input
            v-model="dataForm.nama_puskesmas"
            disabled
          ></b-form-input>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label> NIP </template>
          <b-form-input
            v-model="dataForm.nip"
            type="text"
            disabled
          ></b-form-input>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label> NIK </template>
          <b-form-input
            v-model="dataForm.nik"
            type="text"
            disabled
          ></b-form-input>
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <b-button variant="secondary" @click="$bvModal.hide('modal-input-user-tenaga-medis')">
          Batal
        </b-button>
        <b-button variant="primary" :disabled="busy || !isValid" @click="simpan()">{{
          button
        }}</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import _ from "lodash";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  name: "modalInput",
  props: ['is_data'],
  data() {
    return {
      dataForm: {
        username: null,
        password: null,
        // password_confirm: null,
        ms_role_id: null,
        nama: null,
        alamat: null,
        puskesmas_id: null,
        nip: null,
        nik: null,
      },
      src1: "",
      selected: null,
      busy: false,
      button: "Simpan",
      listRole: [],
      is_text: false
    };
  },
  computed: {
    formString() {
      return JSON.stringify(this.dataForm, null, 4);
    },
    isValid() {
      return !this.$v.dataForm.$invalid;
    },
    isDirty() {
      return this.$v.dataForm.$anyDirty;
    },
  },
  mixins: [validationMixin],
  validations: {
    dataForm: {
      username: { required },
      password: { required },
      // password_confirm: { requirePassword: requiredIf(this.dataForm.password), samePassword: sameAs(this.dataForm.password) },
      ms_role_id: { required },
    },
  },
  async mounted() {
    const vm = this
    vm.getDatas()
  },
  methods: {
    checkIfValid(fieldName) {
      const field = this.$v.dataForm[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    async openModal(){
      const vm = this
      await vm.getDatas()
      console.log('vm.listRole', vm.listRole)
      console.log('data', vm.is_data)
      vm.dataForm = {
        username: null,
        password: null,
        ms_role_id: null,
        nama: vm.is_data.nama_tenaga_medis,
        alamat: vm.is_data.alamat,
        nama_puskesmas: vm.is_data.nama_puskesmas,
        puskesmas_id: vm.is_data.puskesmas_id,
        nip: vm.is_data.nip,
        nik: vm.is_data.nik_tenaga_medis,
        tenaga_medis_id: vm.is_data.tenaga_medis_id,
      }
    },
    async getDatas(){
      const vm = this
      let roles = await vm.$axios.post("ms_role/list");
      // console.log('roles', roles)
      if (roles.data.status == 200) {
        vm.listRole = roles.data.data.filter((item) => {
          return vm.role != "super admin" ? item.nama_role != "super admin" && item.nama_role != "dinkes" : item;
        });
      }
    },
    simpan() {
      let vm = this;
      vm.busy = true;
      vm.button = "Mohon Tunggu";
      // console.log(vm.dataForm);
      // username, password, role, nama, alamat, puskesmas_id
      vm.$axios
        .post(`/tenaga_medis/create_user_tenaga_medis`, {
          ...vm.dataForm,
          ms_role_id: vm.dataForm.ms_role_id ? vm.dataForm.ms_role_id.ms_role_id : null,
        })
        .then((res) => {
          // console.log('res', res)
          if (res.data.message == "sukses") {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$v.$reset();
            vm.$emit("alertFromChild", {
              variant: "success",
              msg: "BERHASIL MEMBUAT USER TENAGA MEDIS",
              showing: true,
            });
            vm.$bvModal.hide("modal-input-user-tenaga-medis");
            vm.resetModal();
            // }
          } else {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "danger",
              msg: _.toUpper(res.data.message),
              showing: true,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          vm.button = "Simpan";
          vm.busy = false;
          vm.$emit("alertFromChild", {
            variant: "danger",
            msg: "TERJADI KESALAHAN PADA SERVER",
            showing: true,
          });
        });
    },
    resetModal() {
      this.$v.$reset();
      this.dataForm = {
        username: null,
        password: null,
        // password_confirm: null,
        role: null,
        nama: null,
        alamat: null,
        puskesmas_id: null,
        nip: null,
        nik: null,
      };
    },
  },
};
</script>
