<template>
  <div>
    <b-modal
      id="modal-edit-tenaga-medis"
      size="lg"
      centered
      :title="'Update Data Tenaga Medis'"
      header-bg-variant="primary"
      header-text-variant="light"
      @show="openModal()"
      @hidden="tutupModal"
      v-if="is_data"
    >
      <b-form-group label-cols-md="3">
        <template v-slot:label> Nama <span class="text-danger">*</span> </template>
        <b-form-input
          :state="checkIfValid('nama_tenaga_medis')"
          type="text"
          v-model="$v.is_data.nama_tenaga_medis.$model"
          debounce="1000"
          @change="tambahKeyData('nama_tenaga_medis')"
        ></b-form-input>
      </b-form-group>

      <b-form-group label-cols-md="3">
        <template v-slot:label>
          Tempat Lahir
        </template>
        <b-form-input
          :state="checkIfValid('tempat_lahir_tenaga_medis')"
          type="text"
          v-model="$v.is_data.tempat_lahir_tenaga_medis.$model"
          debounce="1000"
          @change="tambahKeyData('tempat_lahir_tenaga_medis')"
        ></b-form-input>
      </b-form-group>
      <b-form-group label-cols-md="3">
        <template v-slot:label>
          Tanggal Lahir
        </template>
        <date-picker
          style="width: 100%"
          id="tanggal_lahir_tenaga_medis"
          format="DD-MM-YYYY"
          v-model="$v.is_data.tanggal_lahir_tenaga_medis.$model"
          @change="tambahKeyData('tanggal_lahir_tenaga_medis')"
        ></date-picker>
      </b-form-group>
      <b-form-group label-cols-md="3">
        <template v-slot:label> Agama <span class="text-danger">*</span> </template>
        <b-form-select
          :state="checkIfValid('agama_tenaga_medis')"
          :options="option_agama"
          v-model="$v.is_data.agama_tenaga_medis.$model"
          debounce="1000"
          @change="tambahKeyData('agama_tenaga_medis')"
        ></b-form-select>
      </b-form-group>
      <b-form-group label-cols-md="3">
        <template v-slot:label>
          Jenis Kelamin <span class="text-danger">*</span>
        </template>
        <b-form-select
          :state="checkIfValid('jk_tenaga_medis')"
          :options="option_jenis_kelamin"
          v-model="$v.is_data.jk_tenaga_medis.$model"
          debounce="1000"
          @change="tambahKeyData('jk_tenaga_medis')"
        ></b-form-select>
      </b-form-group>
      <b-form-group label-cols-md="3">
        <template v-slot:label> No. HP</template>
        <b-form-input
          :state="checkIfValid('no_hp_tenaga_medis')"
          type="text"
          v-model="$v.is_data.no_hp_tenaga_medis.$model"
          debounce="1000"
          @change="tambahKeyData('no_hp_tenaga_medis')"
        ></b-form-input>
      </b-form-group>
      <b-form-group label-cols-md="3">
        <template v-slot:label> Email</template>
        <b-form-input
          :state="checkIfValid('email_tenaga_medis')"
          type="text"
          v-model="$v.is_data.email_tenaga_medis.$model"
          debounce="1000"
          @change="tambahKeyData('email_tenaga_medis')"
        ></b-form-input>
      </b-form-group>
      <b-form-group label-cols-md="3">
        <template v-slot:label> Diploma </template>
        <b-form-input
          :state="checkIfValid('edu_diploma')"
          type="text"
          v-model="$v.is_data.edu_diploma.$model"
          debounce="1000"
          @change="tambahKeyData('edu_diploma')"
        ></b-form-input>
      </b-form-group>
      <b-form-group label-cols-md="3">
        <template v-slot:label> Sarjana </template>
        <b-form-input
          :state="checkIfValid('edu_bachelor')"
          type="text"
          v-model="$v.is_data.edu_bachelor.$model"
          debounce="1000"
          @change="tambahKeyData('edu_bachelor')"
        ></b-form-input>
      </b-form-group>
      <b-form-group label-cols-md="3">
        <template v-slot:label> Doctor </template>
        <b-form-input
          :state="checkIfValid('edu_doctor')"
          type="text"
          v-model="$v.is_data.edu_doctor.$model"
          debounce="1000"
          @change="tambahKeyData('edu_doctor')"
        ></b-form-input>
      </b-form-group>
      <b-form-group label-cols-md="3">
        <template v-slot:label>
          Keahlian Khusus
        </template>
        <b-form-input
          :state="checkIfValid('keahlian_khusus')"
          type="text"
          v-model="$v.is_data.keahlian_khusus.$model"
          debounce="1000"
          @change="tambahKeyData('keahlian_khusus')"
        ></b-form-input>
      </b-form-group>
      <b-form-group label-cols-md="3">
        <template v-slot:label> Kode Tenaga Medis BPJS</template>
        <Multiselect
          id="kode_bpjs_tenaga_medis"
          v-model="$v.is_data.kode_bpjs_tenaga_medis.$model"
          :options="listTenagaMedisBpjs"
          :multiple="false"
          :searchable="true"
          :close-on-select="true"
          :show-labels="false"
          label="text"
          placeholder="-- Pilih Kode Tenaga Medis BPJS --"
          size="sm"
        ></Multiselect>
      </b-form-group>
      <b-form-group label-cols-md="3">
        <template v-slot:label>
          Surat Tanda Registrasi
        </template>
        <b-form-input
          :state="checkIfValid('kj_str_number')"
          type="text"
          v-model="$v.is_data.kj_str_number.$model"
          debounce="1000"
          @change="tambahKeyData('kj_str_number')"
        ></b-form-input>
      </b-form-group>
      <b-form-group label-cols-md="3">
        <template v-slot:label>
          Tanggal Surat
        </template>
        <date-picker
          style="width: 100%"
          id="tanggal_surat"
          format="DD-MM-YYYY"
          :state="checkIfValid('tanggal_surat')"
          type="text"
          v-model="$v.is_data.tanggal_surat.$model"
          @change="tambahKeyData('tanggal_surat')"
        ></date-picker>
      </b-form-group>
      <b-form-group label-cols-md="3">
        <template v-slot:label>
          Tanggal Kadaluarsa
        </template>
        <date-picker
          style="width: 100%"
          id="tanggal_kadaluarsa_surat"
          format="DD-MM-YYYY"
          :state="checkIfValid('tanggal_kadaluarsa_surat')"
          type="text"
          v-model="$v.is_data.tanggal_kadaluarsa_surat.$model"
          @change="tambahKeyData('tanggal_kadaluarsa_surat')"
        ></date-picker>
      </b-form-group>
      <b-form-group label-cols-md="3">
        <template v-slot:label> NIK <span class="text-danger">*</span> </template>
        <b-form-input
          :state="checkIfValid('nik_tenaga_medis')"
          type="text"
          v-model="$v.is_data.nik_tenaga_medis.$model"
          debounce="1000"
          @change="tambahKeyData('nik_tenaga_medis')"
        ></b-form-input>
      </b-form-group>
      <b-form-group label-cols-md="3">
        <template v-slot:label> NPWP </template>
        <b-form-input
          :state="checkIfValid('npwp_tenaga_medis')"
          type="text"
          v-model="$v.is_data.npwp_tenaga_medis.$model"
          debounce="1000"
          @change="tambahKeyData('npwp_tenaga_medis')"
        ></b-form-input>
      </b-form-group>

      <b-form-group label-cols-md="3">
        <template v-slot:label> Specialist </template>
        <b-form-select
          v-model="$v.is_data.ms_specialist_id.$model"
          :options="option_specialist"
          :state="checkIfValid('ms_specialist_id')"
          debounce="1000"
          @change="tambahKeyData('ms_specialist_id')"
        ></b-form-select>
      </b-form-group>
      <b-form-group label-cols-md="3">
        <template v-slot:label> Kualifikasi</template>
        <b-form-select
          v-model="$v.is_data.ms_kualifikasi_id.$model"
          :options="option_kualifikasi"
          :state="checkIfValid('ms_kualifikasi_id')"
          debounce="1000"
          @change="tambahKeyData('ms_kualifikasi_id')"
        ></b-form-select>
      </b-form-group>
      <b-form-group label-cols-md="3">
        <template v-slot:label>
          Jenis Tenaga Medis <span class="text-danger">*</span>
        </template>
        <b-form-select
          v-model="$v.is_data.ms_jenis_tenaga_medis_id.$model"
          :options="option_jenis_tenaga_medis"
          :state="checkIfValid('ms_jenis_tenaga_medis_id')"
          debounce="1000"
          @change="tambahKeyData('ms_jenis_tenaga_medis_id')"
        ></b-form-select>
      </b-form-group>
      <b-form-group label-cols-md="3">
          <template v-slot:label>
            Jabatan <span class="text-danger">*</span>
          </template>
          <b-form-input
            :state="checkIfValid('jabatan')"
            type="text"
            v-model="$v.is_data.jabatan.$model"
          ></b-form-input>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            NIP <span class="text-danger">*</span>
          </template>
          <b-form-input
            :state="checkIfValid('nip')"
            type="text"
            v-model="$v.is_data.nip.$model"
          ></b-form-input>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Tenaga Medis <span class="text-danger">*</span>
          </template>
          <multiselect
            v-model="is_data.non_tenaga_medis"
            :options="$store.state.data_static.tenaga_medis"
            :multiple="false"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            label="text"
            placeholder="-- Tenaga Medis --"
            size="sm"
            :state="checkIfValid('non_tenaga_medis')"
          ></multiselect>
        </b-form-group>
      <!-- <b-form-group label-cols-md="3">
        <template v-slot:label>
          Foto Tenaga Medis <span class="text-danger">*</span>
        </template>
        <b-form-file
          v-model="file1"
          @change="updateFoto('file1')"
        ></b-form-file>
      </b-form-group>

      <b-form-group label-cols-md="3">
        <template v-slot:label>
          Tanda Tangan <span class="text-danger">*</span>
        </template>
        <b-form-file
          v-model="file2"
          @change="updateFoto('file2')"
        ></b-form-file>
      </b-form-group> -->

      <CInputFile
        accept="image/jpeg, image/png, image/gif"
        label="Upload Foto"
        id="file1"
        custom
        horizontal
        ref="file1"
        @change="handleFile('file1'), tambahKeyData('file1')"
        :placeholder="place('file1')"
        class="mb-3"
      />
      <CInputFile
        accept="image/jpeg, image/png, image/gif"
        label="Upload Tanda Tangan"
        id="file2"
        custom
        horizontal
        ref="file2"
        @change="handleFile('file2'), tambahKeyData('file2')"
        :placeholder="place('file2')"
        class="mb-3"
      />

      <b-container>
        <b-row>
          <b-col cols="6" v-if="is_data.foto_tenaga_medis" class="text-center">
            <h6>Foto Tenaga Medis</h6>
            <img
              :src="file1 ? preview_file1 : is_data.foto_tm"
              width="150"
              height="auto"
              alt="tenaga medis"
              @click="openInput('file1')"
            />
          </b-col>
          <b-col cols="6" v-if="is_data.tanda_tangan" class="text-center">
            <h6>Foto Tanda Tangan</h6>
            <img
              v-if="file2 == ''"
              :src="is_data.foto_tt"
              width="150"
              height="auto"
              alt="tanda tangan"
              @click="openInput('file2')"
            />
            <img
              v-else
              :src="preview_file2"
              width="150"
              height="auto"
              alt="tanda tangan"
              @click="openInput('file2')"
            />
          </b-col>
        </b-row>
      </b-container>

      <template #modal-footer>
        <b-button variant="secondary" @click="$bvModal.hide('modal-edit-tenaga-medis')">
          Batal
        </b-button>
        <b-button variant="primary" :disabled="busy || !isValid" @click="simpan()">{{
          button
        }}</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import _ from "lodash";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
// import Multiselect from 'vue-multiselect'

export default {
  name: "modalEdit",
  props: [
    "fileName",
    "data_edit",
    "listTenagaMedisBpjs",
    "option_specialist",
    "option_kualifikasi",
    "option_jenis_tenaga_medis",
    "option_jenis_kelamin",
    "option_agama",
  ],
  // components: {
  //   Multiselect,
  // },
  data() {
    return {
      busy: false,
      button: "Simpan",
      is_data: null,
      file_name: this.fileName,
      file1: "",
      file2: "",
      locale: "id-ID",
      test_edit: {},
      preview_file1: "",
      preview_file2: "",
    };
  },
  computed: {
    computed_val: {
      get() {
        return this.is_data;
      },
      set(newVal) {
        this.is_data = newVal;
      },
    },
    formString() {
      return JSON.stringify(this.data_edit, 4, null);
    },
    isValid() {
      return !this.$v.is_data.$invalid;
    },
    isDirty() {
      return this.$v.is_data.$anyDirty;
    },
    pageName() {
      let words = this.file_name.includes("ms_")
        ? this.file_name.replace("ms_", "").split("_")
        : this.file_name.split("_");
      let capitalizedWords = words.map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      });
      return capitalizedWords.join(" ");
    },
  },
  watch: {
    data_edit(newVal) {
      const vm = this
      vm.is_data = newVal;
      if (vm.is_data.tanggal_lahir_tenaga_medis) {
        vm.is_data.tanggal_lahir_tenaga_medis = new Date(
          vm.is_data.tanggal_lahir_tenaga_medis
        );
      }
      if (vm.is_data.tanggal_surat) {
        vm.is_data.tanggal_surat = new Date(vm.is_data.tanggal_surat);
      }
      if (vm.is_data.tanggal_kadaluarsa_surat) {
        vm.is_data.tanggal_kadaluarsa_surat = new Date(
          vm.is_data.tanggal_kadaluarsa_surat
        );
      }
      // console.log(vm.$findKey(vm.listTenagaMedisBpjs, vm.is_data.kode_bpjs_tenaga_medis, true, 'kdDokter'))
      // console.log(vm.$findKey(vm.listTenagaMedisBpjs, vm.is_data.kode_bpjs_tenaga_medis, false, 'kdDokter'))
      if(vm.listTenagaMedisBpjs.length > 0){
        vm.is_data.kode_bpjs_tenaga_medis = vm.$findKey(vm.listTenagaMedisBpjs, vm.is_data.kode_bpjs_tenaga_medis, true, 'kdDokter')
      }else{
        vm.is_data.kode_bpjs_tenaga_medis = {
          text: vm.is_data.kode_bpjs_tenaga_medis,
          id: vm.is_data.kode_bpjs_tenaga_medis
        }
      }
      
      // console.log('vm.listTenagaMedisBpjs', vm.listTenagaMedisBpjs)
      // console.log('vm.is_data.kode_bpjs_tenaga_medis', vm.is_data.kode_bpjs_tenaga_medis)
    },
  },

  mixins: [validationMixin],
  validations: {
    is_data: {
      nama_tenaga_medis: { required },
      tempat_lahir_tenaga_medis: {  },
      tanggal_lahir_tenaga_medis: {  },
      agama_tenaga_medis: { required },
      jk_tenaga_medis: { required },
      no_hp_tenaga_medis: {  },
      email_tenaga_medis: {  },
      edu_bachelor: {  },
      edu_diploma: {  },
      edu_doctor: {  },
      keahlian_khusus: {   },
      kode_bpjs_tenaga_medis: {  },
      kj_str_number: {  },
      tanggal_surat: {  },
      tanggal_kadaluarsa_surat: {  },
      nik_tenaga_medis: { required },
      npwp_tenaga_medis: {  },
      ms_specialist_id: {  },
      ms_kualifikasi_id: {  },
      ms_jenis_tenaga_medis_id: { required },
      jabatan: { required },
      nip: { required },
      non_tenaga_medis: { required },
    },
  },
  methods: {
    checkIfValid(fieldName) {
      const field = this.$v.is_data[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    openModal(){
      const vm = this
      vm.is_data.non_tenaga_medis = vm.$findKey(vm.$store.state.data_static.tenaga_medis, vm.is_data.non_tenaga_medis, true, 'value')
    },
    simpan() {
      let vm = this;
      vm.busy = true;
      vm.button = "Mohon Tunggu";
      let is_fd = false;
      let fd = new FormData();
      if ("file1" in vm.test_edit || "file2" in vm.test_edit) {
        is_fd = true;
        // if (('file1' in vm.test_edit)) {
        //   fd.append("file1", vm.file1);
        // }
        // if (('file2' in vm.test_edit)) {
        //   fd.append("file2", vm.file2);
        // }

        for (const key in vm.test_edit) {
          if (Object.hasOwnProperty.call(vm.test_edit, key)) {
            const el = vm.test_edit[key];
            if (key == "file1" && !fd.has(key)) {
              fd.append(key, vm.file1);
            }
            if (key == "file2" && !fd.has(key)) {
              fd.append(key, vm.file2);
            }
            if (key != 'file1' && key != 'file2' && key != 'id') {
              fd.append(key, el);
            }
          }
        }

        // id, nama_tenaga_medis, tempat_lahir_tenaga_medis, tanggal_lahir_tenaga_medis, agama_tenaga_medis, jk_tenaga_medis, no_hp_tenaga_medis, email_tenaga_medis, edu_bachelor, edu_diploma, edu_doctor, keahlian_khusus, kode_bpjs_tenaga_medis, kj_str_number, tanggal_surat, tanggal_kadaluarsa_surat, nik_tenaga_medis, npwp_tenaga_medis, ms_specialist_id, ms_kualifikasi_id, ms_jenis_tenaga_medis_id, user_id

        fd.append("id", vm.is_data.tenaga_medis_id);
        // fd.append("nik_tenaga_medis", vm.is_data.nik_tenaga_medis);
        // fd.append("nama_tenaga_medis", vm.is_data.nama_tenaga_medis);
        // fd.append("tempat_lahir_tenaga_medis", vm.is_data.tempat_lahir_tenaga_medis);
        // fd.append("tanggal_lahir_tenaga_medis", vm.$moment(vm.is_data.tanggal_lahir_tenaga_medis).format('YYYY-MM-DD'));
        // fd.append("agama_tenaga_medis", vm.is_data.agama_tenaga_medis);
        // fd.append("jk_tenaga_medis", vm.is_data.jk_tenaga_medis);
        // fd.append("no_hp_tenaga_medis", vm.is_data.no_hp_tenaga_medis);
        // fd.append("email_tenaga_medis", vm.is_data.email_tenaga_medis);
        // fd.append("edu_bachelor", vm.is_data.edu_bachelor);
        // fd.append("edu_diploma", vm.is_data.edu_diploma);
        // fd.append("edu_doctor", vm.is_data.edu_doctor);
        // fd.append("keahlian_khusus", vm.is_data.keahlian_khusus);
        // fd.append("kode_bpjs_tenaga_medis", vm.is_data.kode_bpjs_tenaga_medis);
        // fd.append("kj_str_number", vm.is_data.kj_str_number);
        // fd.append("tanggal_surat", vm.$moment(vm.is_data.tanggal_surat).format('YYYY-MM-DD'));
        // fd.append("tanggal_kadaluarsa_surat", vm.$moment(vm.is_data.tanggal_kadaluarsa_surat).format('YYYY-MM-DD'));
        // fd.append("nik_tenaga_medis", vm.is_data.nik_tenaga_medis);
        // fd.append("npwp_tenaga_medis", vm.is_data.npwp_tenaga_medis);
        // fd.append("ms_specialist_id", vm.is_data.ms_specialist_id);
        // fd.append("ms_kualifikasi_id", vm.is_data.ms_kualifikasi_id);
        // fd.append("ms_jenis_tenaga_medis_id", vm.is_data.ms_jenis_tenaga_medis_id);
        // fd.append("user_id", vm.is_data.user_id);
      }
      // vm.test_edit.nik_tenaga_medis = vm.is_data.nik_tenaga_medis,
      vm.test_edit.id = vm.is_data.tenaga_medis_id
      vm.test_edit.kode_bpjs_tenaga_medis = vm.is_data.kode_bpjs_tenaga_medis ? vm.is_data.kode_bpjs_tenaga_medis.kdDokter : null
      vm.test_edit.non_tenaga_medis = vm.is_data.non_tenaga_medis ? vm.is_data.non_tenaga_medis.value : null
      console.log("fd", fd);
      console.log("test_edit", this.test_edit);

      vm.$axios
        .post(`/${vm.file_name}/update`, is_fd ? fd : vm.test_edit)
        .then((res) => {
          if (res.data.message == "sukses") {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$v.$reset();
            vm.$emit("alertFromChild", {
              variant: "success",
              msg: "BERHASIL MENGUBAH " + this.pageName.toUpperCase(),
              showing: true,
            });
            vm.$bvModal.hide("modal-edit-tenaga-medis");
            vm.resetModal();
          } else {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "danger",
              msg: _.toUpper(res.data.message),
              showing: true,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          vm.button = "Simpan";
          vm.busy = false;
          vm.$emit("alertFromChild", {
            variant: "danger",
            msg: "TERJADI KESALAHAN PADA SERVER",
            showing: true,
          });
        });
    },
    tutupModal() {
      this.$emit("tutupModal");
    },
    updateFoto() {
      let vm = this;
      let fd = new FormData();
      fd.append("file1", vm.file1);
      fd.append("file2", vm.file2);

      console.log("FormData", fd);
    },
    handleFile(val) {
      this[val] = this.$refs[val].$data.state[0];

      let file = this.$refs[val].$data.state[0]
      this['preview_' + val] = URL.createObjectURL(file)
    },
    place(val) {
      let vm = this;
      if (vm[val] == "" || vm[val] == undefined) {
        return "Pilih File";
      } else {
        return vm[val].name;
      }
    },
    tambahKeyData(key) {
      if (!("id" in this.test_edit)) {
        this.test_edit["id"] = this.is_data.tenaga_medis_id;
      }
      this.test_edit[key] = this.is_data[key];
      console.log(this.test_edit);
    },
    openInput(key) {
      console.log(this.$refs[key].$el);
      this.$refs[key].$el.focus();
      let inputForm = document.getElementById(key);
      inputForm.click();
    },
    resetModal() {
      this.$v.$reset();
      this.is_data = {
        nama_tenaga_medis: null,
        tempat_lahir_tenaga_medis: null,
        tanggal_lahir_tenaga_medis: null,
        agama_tenaga_medis: null,
        jk_tenaga_medis: null,
        no_hp_tenaga_medis: null,
        email_tenaga_medis: null,
        edu_bachelor: null,
        edu_diploma: null,
        edu_doctor: null,
        keahlian_khusus: null,
        kode_bpjs_tenaga_medis: null,
        kj_str_number: null,
        tanggal_surat: null,
        tanggal_kadaluarsa_surat: null,
        nik_tenaga_medis: null,
        npwp_tenaga_medis: null,
        ms_specialist_id: null,
        ms_kualifikasi_id: null,
        ms_jenis_tenaga_medis_id: null,
      };
      this.test_edit = {};
      this.place();
      this.file1 = ""
      this.file2 = ""
      this.preview_file1 = ""
      this.preview_file2 = ""
    },
  },
};
</script>
