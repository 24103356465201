<template>
  <div>
    <b-modal
      id="modal-detail-tenaga-medis"
      size="lg"
      centered
      :title="'Detail ' + pageName"
      header-bg-variant="primary"
      header-text-variant="light"
    >
      <b-container fluid>
        <b-row>
          <b-col cols="12" sm="12" md="12" lg="12">
            <h3><strong>DATA DETAIL</strong></h3>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" sm="12" md="12" lg="12">
            <table>
              <tr>
                <td>Nama</td>
                <td style="width: 15px">:</td>
                <td>{{ data_detail.nama_tenaga_medis }}</td>
              </tr>
              <tr>
                <td>NIK</td>
                <td>:</td>
                <td>{{ data_detail.nik_tenaga_medis }}</td>
              </tr>
              <tr>
                <td>Tempat Lahir</td>
                <td>:</td>
                <td>{{ data_detail.tempat_lahir_tenaga_medis }}</td>
              </tr>
              <tr>
                <td>Tanggal Lahir</td>
                <td>:</td>
                <td>{{ $moment(data_detail.tanggal_lahir_tenaga_medis).format("dddd, DD MMMM YYYY") }}</td>
              </tr>
              <tr>
                <td>Agama</td>
                <td>:</td>
                <td>{{ data_detail.agama_tenaga_medis }}</td>
              </tr>
              <tr>
                <td>Jenis Kelamin</td>
                <td>:</td>
                <td>{{ data_detail.jk_tenaga_medis }}</td>
              </tr>
              <tr>
                <td>NPWP</td>
                <td>:</td>
                <td>{{ data_detail.npwp_tenaga_medis }}</td>
              </tr>
              <tr>
                <td>Email</td>
                <td>:</td>
                <td>{{ data_detail.email_tenaga_medis }}</td>
              </tr>
              <tr>
                <td>BPJS</td>
                <td>:</td>
                <td>{{ data_detail.kode_bpjs_tenaga_medis ? data_detail.kode_bpjs_tenaga_medis.nmDokter : '-' }}</td>
              </tr>
              <tr>
                <td>Bachelor</td>
                <td>:</td>
                <td>{{ data_detail.edu_bachelor }}</td>
              </tr>
              <tr>
                <td>Diploma</td>
                <td>:</td>
                <td>{{ data_detail.edu_diploma }}</td>
              </tr>
              <tr>
                <td>Email</td>
                <td>:</td>
                <td>{{ data_detail.email_tenaga_medis }}</td>
              </tr>
              <tr>
                <td>No. HP</td>
                <td>:</td>
                <td>{{ data_detail.no_hp_tenaga_medis }}</td>
              </tr>
              <tr>
                <td>Specialist</td>
                <td>:</td>
                <td>{{ data_detail.nama_specialist }}</td>
              </tr>
              <tr>
                <td>Kode Specialist</td>
                <td>:</td>
                <td>{{ data_detail.kode_specialist }}</td>
              </tr>
              <tr>
                <td>Kualifikasi</td>
                <td>:</td>
                <td>{{ data_detail.nama_kualifikasi }}</td>
              </tr>
              <tr>
                <td>Jenis Tenaga Medis</td>
                <td>:</td>
                <td>{{ data_detail.nama_jenis_tenaga_medis }}</td>
              </tr>
              <tr>
                <td>Tanggal Surat</td>
                <td>:</td>
                <td>{{ $moment(data_detail.tanggal_surat).format("dddd, DD MMMM YYYY") }}</td>
              </tr>
              <tr>
                <td>Tanggal Kadaluarsa Surat</td>
                <td>:</td>
                <td>{{ $moment(data_detail.tanggal_kadaluarsa_surat).format("dddd, DD MMMM YYYY") }}</td>
              </tr>
              <!-- <tr>
                <td>Foto</td>
                <td>:</td>
                <td>{{ data_detail.foto_tenaga_medis }}</td>
              </tr>
              <tr>
                <td>Tanda Tangan</td>
                <td>:</td>
                <td>{{ data_detail.tanda_tangan }}</td>
              </tr> -->
            </table>
          </b-col>
        </b-row>
        <b-row class="mt-3 text-center">
          <b-col cols="6">
            <h6>Foto Tenaga Medis</h6>
            <img :src="data_detail.foto_tm" width="150" height="auto" alt="tenaga medis" />
          </b-col>
          <b-col cols="6">
            <h6>Foto Tanda Tangan</h6>
            <img :src="data_detail.foto_tt" width="150" height="auto" alt="tanda tangan" />
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer>
        <b-button variant="secondary" @click="$bvModal.hide('modal-detail-tenaga-medis')">
          Tutup
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  name: "modalDetail",
  props: ["fileName", "data_detail"],
  data() {
    return {
      selected: null,
      busy: false,
      button: "Simpan",
      file_name: this.fileName,
    };
  },
  computed: {
    pageName() {
      let words = this.file_name.includes("ms_")
        ? this.file_name.replace("ms_", "").split("_")
        : this.file_name.split("_");
      let capitalizedWords = words.map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      });
      return capitalizedWords.join(" ");
    },
  },
  methods: {
    simpan() {
      let vm = this;
      vm.busy = true;
      vm.button = "Mohon Tunggu";
      vm.$axios
        .post("/msBank/register", vm.is_data)
        .then((res) => {
          console.log(res, "ini ress");
          if (res.data.message == "sukses") {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$v.$reset();
            vm.$emit("alertFromChild", {
              variant: "success",
              msg: "BERHASIL MENDAFTARKAN MASTER BANK",
              showing: true,
            });
            this.$bvModal.hide("modal-input");
            this.reset();
          } else {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "danger",
              msg: _.toUpper(res.data.message),
              showing: true,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          vm.button = "Simpan";
          vm.busy = false;
          vm.$emit("alertFromChild", {
            variant: "danger",
            msg: "TERJADI KESALAHAN PADA SERVER",
            showing: true,
          });
        });
    },
    reset() {
      this.$v.$reset();
      this.data = {
        nama_bank: "",
      };
    },
    onImageLoadFailure (event) {
      console.log(event);
      event.target.src = '@/assets/new.png'     
    }
  },
};
</script>

<style scoped>
table,
tr,
td {
  padding: 3px;
  font-weight: bold;
  font-size: 15px;
}
</style>
