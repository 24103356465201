<template>
  <div>
    <b-container fluid>
      <b-row>
        <b-col cols="12" md="12" lg="12">
          <b-card header-tag="header" header-bg-variant="dark">
            <template #header>
              <h5 class="mb-0" style="color: #fff">
                <strong>{{ pageName }}</strong>
              </h5>
            </template>
            <b-row>
              <b-col cols="12" md="12" lg="12">
                <b-button
                  
                  variant="primary"
                  v-b-modal.modal-input-tenaga-medis
                  ><CIcon name="cil-plus" /> Tambah Data</b-button
                >
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="12" lg="12">
                <hr />
              </b-col>
            </b-row>

            <b-alert dismissible fade :show="showing" :variant="variant">{{
              msg
            }}</b-alert>

            <b-row>
              <b-col md="3">
                <b-form-group
                  label="Per Halaman"
                  label-for="per-page-select"
                  label-cols-md="6"
                  label-align-md="left"
                  label-size="md"
                  style="background-color: "
                >
                  <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="md"
                  ></b-form-select>
                </b-form-group>
              </b-col>

              <b-col md="6" offset-md="3">
                <b-form-group
                  label="Cari"
                  label-for="filter-input"
                  label-cols-md="3"
                  label-align-md="right"
                  label-size="md"
                >
                  <b-input-group size="md">
                    <b-form-input
                      id="filter-input"
                      v-model="filter"
                      type="search"
                      placeholder="Ketik disini untuk mencari ..."
                    ></b-form-input>

                    <b-input-group-append>
                      <b-button :disabled="!filter" @click="filter = ''" variant="danger"
                        >Hapus</b-button
                      >
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" md="12" lg="12">
                <b-table
                  :items="items"
                  :fields="fields"
                  :current-page="currentPage"
                  :per-page="perPage"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  responsive
                  show-empty
                  small
                  @filtered="onFiltered"
                  bordered
                  striped
                  hover
                  :busy="tableBusy"
                >
                  <template #cell(actions)="item">
                    <div class="d-flex nowrap">
                      <b-button
                        variant="info"
                        class="mr-1"
                        v-c-tooltip.hover.click="'Edit Data'"
                        v-b-modal.modal-detail-tenaga-medis
                        @click="is_data = item.item"
                        ><CIcon name="cil-info" /> {{ item.actions }}</b-button
                      >
                      <b-button
                        variant="warning"
                        class="mr-1"
                        v-c-tooltip.hover.click="'Edit Data'"
                        v-b-modal.modal-edit-tenaga-medis
                        @click="is_data = item.item"
                        ><CIcon name="cil-pencil" /> {{ item.actions }}</b-button
                      >
                      <b-button
                        variant="danger"
                        class="mr-1"
                        v-c-tooltip.hover.click="'Hapus Data'"
                        v-b-modal.modal-delete-tenaga-medis
                        @click="is_data = item.item"
                        ><CIcon name="cil-trash" /> {{ item.actions }}</b-button
                      >
                      <b-button
                        variant="success"
                        class="mr-1"
                        v-c-tooltip.hover.click="'Update Satu Sehat'"
                        @click="updateSatuSehat(item.item)"
                        :disabled="item.item.satu_sehat_id != null"
                        ><CIcon name="cil-check" /> {{ item.actions }}</b-button
                      >
                      <b-button
                        class="mr-1"
                        variant="primary"
                        v-c-tooltip.hover.click="'Create User'"
                        v-b-modal.modal-input-user-tenaga-medis
                        @click="is_data = item.item"
                        v-if="!item.item.user_id"
                        ><CIcon name="cil-plus" /></b-button
                      >
                    </div>
                  </template>
                </b-table>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="5" offset-md="7">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                ></b-pagination>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
    <ModalInputUser
      :is_data="is_data"
      @alertFromChild="triggerAlert($event), getDatas()"
    />
    <modal-input
      :fileName="file_name"
      :listTenagaMedisBpjs="listTenagaMedisBpjs"
      :option_specialist="option_specialist"
      :option_kualifikasi="option_kualifikasi"
      :option_jenis_tenaga_medis="option_jenis_tenaga_medis"
      :option_jenis_kelamin="option_jenis_kelamin"
      :option_agama="option_agama"
      :user_id="user_id"
      @alertFromChild="triggerAlert($event), getDatas()"
    />
    <modal-edit
      :listTenagaMedisBpjs="listTenagaMedisBpjs"
      :option_specialist="option_specialist"
      :option_kualifikasi="option_kualifikasi"
      :option_jenis_tenaga_medis="option_jenis_tenaga_medis"
      :option_jenis_kelamin="option_jenis_kelamin"
      :option_agama="option_agama"
      :fileName="file_name"
      :data_edit="is_data"
      @alertFromChild="triggerAlert($event)"
      @tutupModal="getDatas()"
    />
    <modal-delete
      :fileName="file_name"
      :data_delete="is_data"
      @alertFromChild="triggerAlert($event), getDatas()"
    />
    <modal-detail
      :fileName="file_name"
      :data_detail="is_data"
      @alertFromChild="triggerAlert($event), getDatas()"
    />
  </div>
</template>

<script>
import ModalDelete from "./modalDelete.vue";
import ModalInput from "./modalInput.vue";
import ModalEdit from "./modalEdit.vue";
import ModalDetail from "./modalDetail.vue";
import cbFoto from "@/assets/no_pic_square.png";
import ModalInputUser from "./modalInputUser.vue";

export default {
  components: {
    ModalInput,
    ModalDelete,
    ModalEdit,
    ModalDetail,
    cbFoto,
    ModalInputUser,
  },
  name: "tenaga_medis",
  data() {
    return {
      file_name: "tenaga_medis",
      showing: false,
      variant: "success",
      msg: "",
      is_data: "",
      fields: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          sortable: true,
          class: "table-number text-center",
        },
        {
          key: "nama_tenaga_medis",
          label: "Tenaga Medis",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "nama_jenis_tenaga_medis",
          label: "Jenis Tenaga Medis",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "nama_specialist",
          label: "Specialist",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "nama_kualifikasi",
          label: "Kualifikasi",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "satu_sehat_id",
          label: "Satu Sehat ID",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "username",
          label: "Username",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "actions",
          label: "Actions",
          class: " text-center",
        },
      ],
      items: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      // filterOn: [],
      tableBusy: false,
      listTenagaMedisBpjs: [],
      option_specialist: [],
      option_kualifikasi: [],
      option_jenis_tenaga_medis: [],
      option_jenis_kelamin: [
        { value: "Laki-laki", text: "Laki-laki" },
        { value: "Perempuan", text: "Perempuan" },
      ],
      option_agama: [
        { value: "Islam", text: "Islam" },
        { value: "Kristen", text: "Kristen" },
        { value: "Katolik", text: "Katolik" },
        { value: "Hindu", text: "Hindu" },
        { value: "Budha", text: "Budha" },
        { value: "Konghucu", text: "Konghucu" },
      ],
      user_id: "",
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    pageName() {
      let words = this.file_name.includes("ms_")
        ? this.file_name.replace("ms_", "").split("_")
        : this.file_name.split("_");
      let capitalizedWords = words.map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      });
      return capitalizedWords.join(" ");
    },
    filterOn() {
      // console.log(this.fields.map(el => el.key))
      return this.fields.map((el) => el.key);
    },
    role() {
      return this.$store.state.user_role;
    },
  },
  activated() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    this.getDatas();

    // check if route has params
    if (this.$route.params.user_id) {
      console.log(this.$route.params);
      this.user_id = this.$route.params.user_id;
      this.$bvModal.show("modal-input-tenaga-medis");
    }
    this.getKodeBpjs()
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async getKodeBpjs(){
      const vm = this
      const listTenagaMedisBpjs = await vm.$axios.post('/integrasi_pcare/get_dokter')
      // console.log(listTenagaMedisBpjs, 'ini list bpjs');
      vm.listTenagaMedisBpjs = listTenagaMedisBpjs.data.status == 200 ? listTenagaMedisBpjs.data.data.map(x=>{
        return {
          ...x,
          text: `${x.kdDokter} - ${x.nmDokter}`
        }
      }) : []
      // console.log('listTenagaMedisBpjs', listTenagaMedisBpjs)
    },
    async getDatas() {
      const vm = this;
      vm.tableBusy = true;

      //tenaga medis
      let res = await vm.$axios.post(`/${vm.file_name}/list`);
      console.log(vm.file_name, res);
      
      vm.items = res.data.data;
      for (let i = 0; i < vm.items.length; i++) {
        let x = vm.items[i];
        x.no = i + 1;
        x.id = x.ms_tenaga_medis_id;
        x.foto_tm = vm.$store.state.config_ip + "/" + x.foto_tenaga_medis;
        x.foto_tt = vm.$store.state.config_ip + "/" + x.tanda_tangan;
        let foto1 = await vm.$axios(x.foto_tm);
        x.foto_tm = foto1.data.status == 404 ? cbFoto : x.foto_tm;
        let foto2 = await vm.$axios(x.foto_tt);
        x.foto_tt = foto2.data.status == 404 ? cbFoto : x.foto_tt;
        // x.kode_bpjs_tenaga_medis = vm.$findKey(vm.listTenagaMedisBpjs, x.kode_bpjs_tenaga_medis, true, 'kdDokter')
        // console.log('listTenagaMedisBpjs', vm.listTenagaMedisBpjs)
        // console.log('x.kode_bpjs_tenaga_medis', x.kode_bpjs_tenaga_medis)
      }

      vm.totalRows = vm.items.length;
      vm.tableBusy = false;


      //kelas terapi
      let option_specialist = await vm.$axios.post("/ms_specialist/list");
      vm.option_specialist =
        option_specialist.data.status == 200
          ? option_specialist.data.data.map((x) => {
              return { value: x.id, text: x.nama_specialist };
            })
          : [];
      //golongan barang
      let option_kualifikasi = await vm.$axios.post("/ms_kualifikasi/list");
      vm.option_kualifikasi =
        option_kualifikasi.data.status == 200
          ? option_kualifikasi.data.data.map((x) => {
              return { value: x.id, text: x.nama_kualifikasi };
            })
          : [];
      //produsen
      let option_jenis_tenaga_medis = await vm.$axios.post("/ms_jenis_tenaga_medis/list");
      vm.option_jenis_tenaga_medis =
        option_jenis_tenaga_medis.data.status == 200
          ? option_jenis_tenaga_medis.data.data.map((x) => {
              return { value: x.id, text: x.nama_jenis_tenaga_medis };
            })
          : [];
    },
    updateSatuSehat(x){
      let vm = this
      // console.log(x, 'ini x');
      vm.$axios.post("/satu_sehat/practitioner/get_practitioner", {
        identifier: x.nik_tenaga_medis,
      })
      .then((res) => {
        // console.log(res);
        if(res.data.status == 200 && res.data.message == "sukses"){
          if(res.data.data[0].entry.length){
            let id_satu_sehat = res.data.data[0].entry[0].resource.id
            vm.$axios.post("/tenaga_medis/update", {
              id: x.tenaga_medis_id,
              satu_sehat_id: id_satu_sehat,
            })
            .then((res) => {
              if(res.data.status == 200 && res.data.message == "sukses"){
                vm.$store.commit("set_alert", { variant: "success", msg: "BERHASIL UPDATE DATA SATU SEHAT" })
                vm.getDatas()
              }
              else{
                vm.$store.commit("set_alert", { variant: "success", msg: res.data.message })
              }
            })
            .catch((err) => {
              vm.$store.commit("set_alert", { variant: "danger", msg: err.message })
            })
          }else {
            vm.$store.commit("set_alert", { variant: "danger", msg: "DOKTER BELUM TERDAFTAR DI SATU SEHAT" })
          }
        }else {
          vm.$store.commit("set_alert", { variant: "danger", msg: res.data.message })
        }
      })
      .catch((err) => {
        vm.$store.commit("set_alert", { variant: "danger", msg: err.message })
      })
    },
    triggerAlert(event) {
      let vm = this;
      vm.$store.commit("set_alert", event);
    },
  },
};
</script>
