<template>
  <div>
    <b-modal
      id="modal-input-tenaga-medis"
      size="lg"
      centered
      :title="'Tambah Data ' + pageName"
      header-bg-variant="primary"
      header-text-variant="light"
      @hidden="resetModal"
      @shown="getDataUser"
    >
      <b-form>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Nama <span class="text-danger">*</span>
          </template>
          <b-form-input
            :state="checkIfValid('nama_tenaga_medis')"
            type="text"
            v-model="$v.is_data.nama_tenaga_medis.$model"
          ></b-form-input>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Tempat Lahir
          </template>
          <b-form-input
            :state="checkIfValid('tempat_lahir_tenaga_medis')"
            type="text"
            v-model="$v.is_data.tempat_lahir_tenaga_medis.$model"
          ></b-form-input>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Tanggal Lahir
          </template>
          <date-picker
            style="width: 100%"
            id="tanggal_daftar"
            v-model="$v.is_data.tanggal_lahir_tenaga_medis.$model"
            :state="checkIfValid('tanggal_lahir_tenaga_medis')"
            :locale="locale"
          ></date-picker>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Agama <span class="text-danger">*</span>
          </template>
          <b-form-select
            :state="checkIfValid('agama_tenaga_medis')"
            :options="option_agama"
            v-model="$v.is_data.agama_tenaga_medis.$model"
          ></b-form-select>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Jenis Kelamin <span class="text-danger">*</span>
          </template>
          <b-form-select
            :state="checkIfValid('jk_tenaga_medis')"
            :options="option_jenis_kelamin"
            v-model="$v.is_data.jk_tenaga_medis.$model"
          ></b-form-select>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            No. HP
          </template>
          <b-form-input
            :state="checkIfValid('no_hp_tenaga_medis')"
            type="text"
            v-model="$v.is_data.no_hp_tenaga_medis.$model"
          ></b-form-input>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Email
          </template>
          <b-form-input
            :state="checkIfValid('email_tenaga_medis')"
            type="text"
            v-model="$v.is_data.email_tenaga_medis.$model"
          ></b-form-input>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Diploma
          </template>
          <b-form-input
            :state="checkIfValid('edu_diploma')"
            type="text"
            v-model="$v.is_data.edu_diploma.$model"
          ></b-form-input>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Sarjana
          </template>
          <b-form-input
            :state="checkIfValid('edu_bachelor')"
            type="text"
            v-model="$v.is_data.edu_bachelor.$model"
          ></b-form-input>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Doctor
          </template>
          <b-form-input
            :state="checkIfValid('edu_doctor')"
            type="text"
            v-model="$v.is_data.edu_doctor.$model"
          ></b-form-input>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Keahlian Khusus
          </template>
          <b-form-input
            :state="checkIfValid('keahlian_khusus')"
            type="text"
            v-model="$v.is_data.keahlian_khusus.$model"
          ></b-form-input>
        </b-form-group>
        <!-- <b-form-group label-cols-md="3">
          <template v-slot:label>
            Kode Tenaga Medis BPJS <span class="text-danger">*</span>
          </template>
          <Multiselect
            id="kode_bpjs_tenaga_medis"
            v-model="$v.is_data.kode_bpjs_tenaga_medis.$model"
            :options="listTenagaMedisBpjs"
            :multiple="false"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            label="text"
            placeholder="-- Pilih Kode Tenaga Medis BPJS --"
            size="sm"
          ></Multiselect>
        </b-form-group> -->
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Surat Tanda Registrasi
          </template>
          <b-form-input
            :state="checkIfValid('kj_str_number')"
            type="text"
            v-model="$v.is_data.kj_str_number.$model"
          ></b-form-input>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Tanggal Surat
          </template>
          <!-- <b-form-datepicker
            :state="checkIfValid('tanggal_surat')"
            type="text"
            v-model="$v.is_data.tanggal_surat.$model"
            :locale="locale"
          ></b-form-datepicker> -->
          <date-picker
            style="width: 100%"
            id="tanggal_surat"
            format="DD-MM-YYYY"
            v-model="$v.is_data.tanggal_surat.$model"
          ></date-picker>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Tanggal Kadaluarsa
          </template>
          <!-- <b-form-datepicker
            :state="checkIfValid('tanggal_kadaluarsa_surat')"
            type="text"
            v-model="$v.is_data.tanggal_kadaluarsa_surat.$model"
            :locale="locale"
          ></b-form-datepicker> -->
          <date-picker
            style="width: 100%"
            id="tanggal_kadaluarsa_surat"
            format="DD-MM-YYYY"
            v-model="$v.is_data.tanggal_kadaluarsa_surat.$model"
          ></date-picker>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            NIK <span class="text-danger">*</span>
          </template>
          <b-form-input
            :state="checkIfValid('nik_tenaga_medis')"
            type="text"
            v-model="$v.is_data.nik_tenaga_medis.$model"
          ></b-form-input>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            NPWP
          </template>
          <b-form-input
            :state="checkIfValid('npwp_tenaga_medis')"
            type="text"
            v-model="$v.is_data.npwp_tenaga_medis.$model"
          ></b-form-input>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Specialist
          </template>
          <b-form-select
            v-model="$v.is_data.ms_specialist_id.$model"
            :options="option_specialist"
            :state="checkIfValid('ms_specialist_id')"
          ></b-form-select>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Kualifikasi
          </template>
          <b-form-select
            v-model="$v.is_data.ms_kualifikasi_id.$model"
            :options="option_kualifikasi"
            :state="checkIfValid('ms_kualifikasi_id')"
          ></b-form-select>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Jenis Tenaga Medis <span class="text-danger">*</span>
          </template>
          <b-form-select
            v-model="$v.is_data.ms_jenis_tenaga_medis_id.$model"
            :options="option_jenis_tenaga_medis"
            :state="checkIfValid('ms_jenis_tenaga_medis_id')"
          ></b-form-select>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Jabatan <span class="text-danger">*</span>
          </template>
          <b-form-input
            :state="checkIfValid('jabatan')"
            type="text"
            v-model="$v.is_data.jabatan.$model"
          ></b-form-input>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            NIP <span class="text-danger">*</span>
          </template>
          <b-form-input
            :state="checkIfValid('nip')"
            type="text"
            v-model="$v.is_data.nip.$model"
          ></b-form-input>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Tenaga Medis <span class="text-danger">*</span>
          </template>
          <multiselect
            v-model="is_data.non_tenaga_medis"
            :options="$store.state.data_static.tenaga_medis"
            :multiple="false"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            label="text"
            placeholder="-- Tenaga Medis --"
            size="sm"
            :state="checkIfValid('non_tenaga_medis')"
          ></multiselect>
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <b-button variant="secondary" @click="$bvModal.hide('modal-input-tenaga-medis')">
          Batal
        </b-button>
        <b-button variant="primary" :disabled="busy || !isValid" @click="simpan()">{{
          button
        }}</b-button>
      </template>
      
    </b-modal>
  </div>
</template>

<script>
import _ from "lodash";
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";

export default {
  name: "modalInput",
  props: [
    "fileName", 
    "listTenagaMedisBpjs", 
    "option_specialist",
    "option_kualifikasi",
    "option_jenis_tenaga_medis",
    "option_jenis_kelamin",
    "option_agama",
    "user_id"
  ],
  data() {
    return {
      // nama_tenaga_medis, tempat_lahir_tenaga_medis, tanggal_lahir_tenaga_medis, agama_tenaga_medis, jk_tenaga_medis, no_hp_tenaga_medis, email_tenaga_medis, edu_bachelor, edu_diploma, edu_doctor, keahlian_khusus, kode_bpjs_tenaga_medis, kj_str_number, tanggal_surat, tanggal_kadaluarsa_surat, nik_tenaga_medis, npwp_tenaga_medis, ms_specialist_id, ms_kualifikasi_id, ms_jenis_tenaga_medis_id, user_id
      is_data: {
        nama_tenaga_medis: null,
        tempat_lahir_tenaga_medis: null,
        tanggal_lahir_tenaga_medis: null,
        agama_tenaga_medis: null,
        jk_tenaga_medis: null,
        no_hp_tenaga_medis: null,
        email_tenaga_medis: null,
        edu_bachelor: null,
        edu_diploma: null,
        edu_doctor: null,
        keahlian_khusus: null,
        kode_bpjs_tenaga_medis: null,
        kj_str_number: null,
        tanggal_surat: null,
        tanggal_kadaluarsa_surat: null,
        nik_tenaga_medis: null,
        npwp_tenaga_medis: null,
        ms_specialist_id: null,
        ms_kualifikasi_id: null,
        ms_jenis_tenaga_medis_id: null,
        jabatan: null,
        nip: null,
        non_tenaga_medis: this.$store.state.data_static.tenaga_medis[1],
      },
      src1: "",
      selected: null,
      busy: false,
      button: "Simpan",
      file_name: this.fileName,
      locale: "id-ID",
    };
  },
  computed: {
    formString() {
      return JSON.stringify(this.is_data, null, 4);
    },
    isValid() {
      return !this.$v.is_data.$invalid;
    },
    isDirty() {
      return this.$v.is_data.$anyDirty;
    },
    pageName() {
      let words = this.file_name.includes("ms_") ? this.file_name.replace("ms_", "").split("_") : this.file_name.split("_")
      let capitalizedWords = words.map((word) => { return word.charAt(0).toUpperCase() + word.slice(1); });
      return capitalizedWords.join(" ");
    },
  },
  mixins: [validationMixin],
  validations: {
    is_data: {
      nama_tenaga_medis: { required },
      tempat_lahir_tenaga_medis: {  },
      tanggal_lahir_tenaga_medis: {  },
      agama_tenaga_medis: { required },
      jk_tenaga_medis: { required },
      no_hp_tenaga_medis: {  },
      email_tenaga_medis: {  },
      edu_bachelor: {  },
      edu_diploma: {  },
      edu_doctor: {  },
      keahlian_khusus: {   },
      // kode_bpjs_tenaga_medis: { required },
      kj_str_number: {  },
      tanggal_surat: {  },
      tanggal_kadaluarsa_surat: {  },
      nik_tenaga_medis: { required, minLength: minLength(16) },
      npwp_tenaga_medis: {  },
      ms_specialist_id: {  },
      ms_kualifikasi_id: {  },
      ms_jenis_tenaga_medis_id: { required },
      jabatan: { required },
      nip: { required },
      non_tenaga_medis: { required },
    },
  },
  methods: {
    checkIfValid(fieldName) {
      const field = this.$v.is_data[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    simpan() {
      let vm = this;
      vm.busy = true;
      vm.button = "Mohon Tunggu";
      // console.log(vm.user_id);
      if (vm.user_id) {
        vm.is_data.user_id = vm.user_id
      }
      vm.$axios
        .post(`/${vm.file_name}/register`, {
          ...vm.is_data,
          kode_bpjs_tenaga_medis: vm.is_data.kode_bpjs_tenaga_medis ? vm.is_data.kode_bpjs_tenaga_medis.kdDokter : null,
          non_tenaga_medis: vm.is_data.non_tenaga_medis ? vm.is_data.non_tenaga_medis.value : null,
        })
        .then((res) => {
          if (res.data.message == "sukses") {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$v.$reset();
            vm.$emit("alertFromChild", {
              variant: "success",
              msg: "BERHASIL MENDAFTARKAN " + this.pageName.toUpperCase(),
              showing: true,
            });
            this.$bvModal.hide("modal-input-tenaga-medis");
            this.resetModal();
          } else {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "danger",
              msg: _.toUpper(res.data.message),
              showing: true,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          vm.button = "Simpan";
          vm.busy = false;
          vm.$emit("alertFromChild", {
            variant: "danger",
            msg: "TERJADI KESALAHAN PADA SERVER",
            showing: true,
          });
        });
    },
    getDataUser() {
      if (this.user_id) {
        console.log("yes", this.$store.state.data_user);
        this.is_data.nama_tenaga_medis = this.$store.state.data_user.nama
        this.is_data.nik_tenaga_medis = this.$store.state.data_user.nik
        this.is_data.nip = this.$store.state.data_user.nip
        this.is_data.ms_jenis_tenaga_medis_id = this.$store.state.data_user.ms_jenis_tenaga_medis_id
      }
    },
    resetModal() {
      this.$v.$reset();
      this.is_data = {
        nama_tenaga_medis: null,
        tempat_lahir_tenaga_medis: null,
        tanggal_lahir_tenaga_medis: null,
        agama_tenaga_medis: null,
        jk_tenaga_medis: null,
        no_hp_tenaga_medis: null,
        email_tenaga_medis: null,
        edu_bachelor: null,
        edu_diploma: null,
        edu_doctor: null,
        keahlian_khusus: null,
        kode_bpjs_tenaga_medis: null,
        kj_str_number: null,
        tanggal_surat: null,
        tanggal_kadaluarsa_surat: null,
        nik_tenaga_medis: null,
        npwp_tenaga_medis: null,
        ms_specialist_id: null,
        ms_kualifikasi_id: null,
        ms_jenis_tenaga_medis_id: null,
        jabatan: null,
        nip: null,
        non_tenaga_medis: this.$store.state.data_static.tenaga_medis[1],
      };
    },
  },
};
</script>
